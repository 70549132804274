import Vue from 'vue'
import Vuex from 'vuex'
// import VuexPersist from 'vuex-persist'

import auth from "./auth"
import catalog from "./catalog"
import message from "./message"

Vue.use(Vuex)

// const vuexLocal = new VuexPersist({
//   storage: window.localStorage,
//   key: 'iugbcatalogs'
// })

export default new Vuex.Store({
  state: {
    drawer: false,
    dictionaryModal: false,
    joinModal: false,
    helpModal: false,
    settingsModal: false,
    cartModal: false,
    privacyModal: false,
    disclaimerModal: false,
    statsModal: false,
    catalogPickerModal: false,
    message: "This is a test"
  },
  mutations: {
    drawer(state, payload) {
      state.drawer = payload
    },
    privacyModal(state, payload) {
      state.privacyModal = payload
    },
    disclaimerModal(state, payload) {
      state.disclaimerModal = payload
    },
    cartModal(state, payload) {
      state.cartModal = payload
    },
    dictionaryModal(state, payload) {
      state.dictionaryModal = payload
    },
    joinModal(state, payload) {
      state.joinModal = payload
    },
    helpModal(state, payload) {
      state.helpModal = payload
    },
    settingsModal(state, payload) {
      state.settingsModal = payload
    },
    catalogPickerModal(state, payload) {
      state.catalogPickerModal = payload
    },
    statsModal(state, payload) {
      state.statsModal = payload
    },
  },
  actions: {
  },
  getters: {
    ready(state, getters, rootState, rootGetters) {
      return rootGetters["auth/logged_in"] && rootGetters["catalog/ready"] 
    },
    drawer(state) {
      return state.drawer
    },
    privacyModal(state) {
      return state.privacyModal
    },
    disclaimerModal(state) {
      return state.disclaimerModal
    },
    cartModal(state) {
      return state.cartModal
    },
    dictionaryModal(state) {
      return state.dictionaryModal
    },
    joinModal(state) {
      return state.joinModal
    },
    helpModal(state) {
      return state.helpModal
    },
    settingsModal(state) {
      return state.settingsModal
    },
    catalogPickerModal(state) {
      return state.catalogPickerModal
    },
    statsModal(state) {
      return state.statsModal
    }
  },
  modules: {
    auth,
    catalog,
    message
  },
  // plugins: [vuexLocal.plugin]
})
