<template>
  <v-footer app id = "footer">
    <v-spacer></v-spacer>
    <span class = "mx-2">&copy;{{ new Date().getFullYear() }} Indiana University</span>
    |
    <a class="mx-2" @click="showPrivacy()">Privacy</a>
    |
    <a class="mx-2" @click="showDisclaimer()">Disclaimer</a>
    <v-spacer></v-spacer>
    <span v-if="ready">{{ countMessage }}</span>
  </v-footer>
</template>

<script>
  export default {
    name: "iugbFooter",
    data() {
      return {

      };
    },
    computed: {
      ready() {
        return this.$store.getters.ready && this.$store.getters['catalog/tabulator'].initialized
      },
      totalCount() {
        return this.$store.getters['catalog/totalCount'] * 1 || 1000
      },
      selectedCount() {
        return this.$store.getters['catalog/selectedCount'] * 1
      },
      filteredCount() {
        return this.$store.getters['catalog/filteredCount'] * 1
      },
      countMessage() {

        let msg = ""

        if(this.filteredCount == this.totalCount) {
          msg = `Displaying ${this.totalCount.toLocaleString()} Records`
        } else {
          msg = `Displaying ${this.filteredCount.toLocaleString()} of ${this.totalCount.toLocaleString()} Records`
        }

        // if(this.selectedCount > 0) {
        //   msg = `${msg} (${this.selectedCount.toLocaleString()} Selected)`
        // }

        return msg

      }
    },
    methods: {
      showDisclaimer() {
        this.$store.commit('disclaimerModal', true)
      },
      showPrivacy() {
        this.$store.commit('privacyModal', true)
      }
    },

  }
</script>