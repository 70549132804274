<template>
  <v-row justify="center">
    <v-dialog
      v-model = "show"
      width = "700"
      persistent
      scrollable
    >
      <v-card>
        <iugb-card-title
          :title="`Select a Catalog (${catalogs.length} Available)`"
          subtitle="Select a catalog"
          icon ="mdi-table-large"
          color = "primary"
          :showClose="catalogLoaded"
          @close = "show = false"
        ></iugb-card-title>
        <v-card-text class = "ma-0 pa-0">
          <v-text-field
            class="ma-1 mt-2"
            dense
            label="Catalog Search"
            outlined
            v-model="searchField"
            prepend-inner-icon="mdi-magnify"
            v-show="all_catalogs ? all_catalogs.length > 6 : false"
            hide-details
            clearable
          ></v-text-field>
          <v-list class="ma-0 pa-0">
            <v-list-item-group
              v-model='catalog'
              color="primary"
            >
              <v-list-item
                v-for="(catalog, index) in catalogs"
                :key="index"
                :value="catalog.name"
              >
                <!-- <v-list-item-icon>
                  <v-icon>mdi-format-list-numbered</v-icon>
                </v-list-item-icon> -->
                <v-list-item-content>
                  <v-list-item-title>
                    {{catalog.name}}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{catalog.description || "No Description Available"}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-container class="ma-0 pa-0">
                    <v-row class="ma-0 pa-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-col class="ma-0 pa-0 px-1" style = "text-align: center; border-right: solid #CCC 1px;" v-bind="attrs" v-on="on">                        
                            <v-icon large color="primary">mdi-table-large</v-icon><br/>
                            <span class="badge" v-text="(catalog.Metadatum.total_records * 1).toLocaleString()"></span>
                          </v-col>
                        </template>
                        <span>Records in Catalog</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-col class="ma-0 pa-0 px-1" style = "text-align: center; border-right: solid #CCC 1px;" v-bind="attrs" v-on="on">                        
                            <v-icon large color="primary">mdi-account</v-icon><br/>
                            <span class="badge" v-text="(catalog.Metadatum.unique_subjects * 1).toLocaleString()"></span>
                          </v-col>
                        </template>
                        <span>Subjects in Catalog</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-col class="ma-0 pa-0 px-1" style = "text-align: center; border-right: solid #CCC 1px;" v-bind="attrs" v-on="on">
                            <v-icon large color="primary">mdi-calendar</v-icon><br/>
                            <span class="badge" v-text="(catalog.Metadatum.unique_visits * 1).toLocaleString()"></span>
                          </v-col>
                        </template>
                        <span>Visits in Catalog</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-col class="ma-0 pa-0" style = "text-align: center;" v-bind="attrs" v-on="on">
                            <v-icon large color="primary">mdi-test-tube</v-icon><br/>
                            <span class="badge" v-text="(catalog.Metadatum.unique_specimen_types * 1).toLocaleString()"></span>
                          </v-col>
                        </template>
                        <span>Specimen Types in Catalog</span>
                      </v-tooltip>
                    </v-row>
                    <!-- <v-row class="ma-0 pa-0">
                      <v-col class="ma-0 pa-0" style = "text-align: center;">                        
                        <span class="badge" v-text="catalog.Metadatum.unique_subjects"></span>
                      </v-col>
                      <v-col class="ma-0 pa-0" style = "text-align: center;">
                        <span class="badge" v-text="catalog.Metadatum.unique_visits"></span>
                      </v-col>
                      <v-col class="ma-0 pa-0" style = "text-align: center;">
                        <span class="badge" v-text="catalog.Metadatum.unique_specimen_types"></span>
                      </v-col>                      
                    </v-row> -->
                  </v-container>
                  <!-- <div style="display: inline-block;">
                      <v-btn text>
                        <v-icon large color="primary">mdi-account</v-icon>
                        <span class="badge" v-text="catalog.Metadatum.unique_subjects"></span>
                      </v-btn>                      
                      <v-btn text>
                        <v-icon large color="primary">mdi-calendar</v-icon>
                        <span class="badge" v-text="catalog.Metadatum.unique_visits"></span>
                      </v-btn>                      
                      <v-btn text>
                        <v-icon large color="primary">mdi-test-tube</v-icon>
                        <span class="badge" v-text="catalog.Metadatum.unique_specimen_types"></span>
                      </v-btn>                      
                  </div> -->

                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="secondary"
            v-if="catalogLoaded"
            @click="show=false"
          >
            Cancel
          </v-btn>
          <v-btn
            color = "primary"
            text
            :disabled="!catalog"
            @click = "setCatalog"
          >
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>    
</template>

<script>

  export default {
    name: "iugbCatalogPicker",
    data() {
      return {
        catalog: null,
        searchField: null,
      }
    },
    computed: {
      show: {
        get() {
          return this.$store.getters.catalogPickerModal
        },
        set(v) {
          this.$store.commit('catalogPickerModal', v)
        }
      },
      catalogLoaded() {
        return this.$store.getters['catalog/report_id'] !== null
      },
      all_catalogs() {
        return this.$store.getters['auth/catalogs']
      },
      catalogs() {
        let catalogs = this.all_catalogs
        if(this.searchField) {
          catalogs = catalogs.filter(catalog => {
            return catalog.name.toLowerCase().includes(this.searchField.toLowerCase())
          })
        }
        return catalogs
      },
      catalog_names() {
        return this.catalogs.map(catalog => catalog.name)
      }
    },
    methods: {
      setCatalog() {
        let x = this.catalogs.filter(catalog => catalog.name === this.catalog)
        this.$router.push(`/${x[0].report_id}`)
        // this.$store.dispatch('catalog/load', {catalog_name: x[0].report_id})
        this.show = false
      }
    },
    watch: {

    }
  }
</script>

<style scoped>

  /* .badge {
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 25px;
    font-size: 11px;
    background: #888;
    color: #fff;
    border-radius: 10px;
    padding: 1px 3px;
    vertical-align: top;
    margin-left: -15px;
    margin-top: 15px;
    z-index: 1000;
  } */

</style>