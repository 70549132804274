const base_url = process.env.VUE_APP_API_URL
import store from '@/store'

export default {
  install: (app) => {
    const verbs = {
      async get(url, type = "json", authenticate = true) {
        let opts = {}
        if(authenticate) {
          opts.headers = {
            "Authorization": `${store.getters['auth/token']}`
          }
        } 
        let resp = await fetch(`${base_url}${url}`, opts)
        if(type == "json") {
          return await resp.json()
        } else {
          return await resp.text()
        }
      },
      async post(url, body = {}, type = "json", authenticate = true) {
        let opts = {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(body)
        }
        if(authenticate) {
          opts.headers["Authorization"] = `${store.getters['auth/token']}`
        } 
        let resp = await fetch(`${base_url}${url}`, opts)
    
        if(resp.status === 201) {
          if(type == "json") {
            return await resp.json()
          } else {
            return await resp.text()
          }
        } else {
          throw new Error(await resp.text())
        }
      },
      async put(url, body = {}, type = "json", authenticate = true) {
        let opts = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(body)
        }
        if(authenticate) {
          opts.headers["Authorization"] = `${store.getters['auth/token']}`
        } 
        let resp = await fetch(`${base_url}${url}`, opts)
    
        if(resp.status === 200) {
          if(type == "json") {
            return await resp.json()
          } else {
            return await resp.text()
          }
        } else {
          throw new Error(await resp.text())
        }
      },
    }
    app.$fetch = verbs

  }
}




