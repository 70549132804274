<template>
  <span>
  <v-row justify="center">
    <v-dialog
      v-model = "show"
      width = "750px"
      scrollable
    >
      <v-card>
        <iugb-card-title
          :title="cart_title"
          icon ="mdi-cart"
          color = "primary"
          showClose
          @close = "show = false"
        ></iugb-card-title>
        <v-tabs
          v-model="tab"
          centered
          grow
          color="primary"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab>
            Specimens
          </v-tab>
          <v-tab>
            Subjects
          </v-tab>
          <v-tab>
            Visits
          </v-tab>
          <v-tab>
            Specimen Types
          </v-tab>
        </v-tabs>         
        <v-card-text class="ma-0 px-0">

          <v-tabs-items v-model="tab">
            <specimen-cart-tab
              name = "Specimens"
              :ids = "specimen_id"
              :dictionary = "dictionary"
              :cart = "cart"
            ></specimen-cart-tab>
            <cart-tab
              name = "Subjects"
              :ids = "subject_id"
              :dictionary = "dictionary"
              :cart = "cart"
            ></cart-tab>
            <cart-tab
              name = "Visits"
              :ids = "visit_id"
              :dictionary = "dictionary"
              :cart = "cart"
            ></cart-tab>
            <cart-tab
              name = "Specimen Types"
              :ids = "specimen_type_id"
              :dictionary = "dictionary"
              :cart = "cart"
            ></cart-tab>
          </v-tabs-items>        
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="show = false">Close</v-btn>
          <v-btn color="primary" @click="downloadCart"><v-icon left>mdi-cart-arrow-down</v-icon>Download Cart</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>  
</span>
</template>

<script>
  export default {
    name: "cartModal",
    data() {
      return {
        tab: 0,
      }
    },
    computed: {
      specimen_id(){
        let id = this.$store.getters['catalog/specimen_id']
        if(!Array.isArray(id)) id = [id]
        return id
      },
      subject_id(){
        let id = this.$store.getters['catalog/subject_id']
        if(!Array.isArray(id)) id = [id]
        return id
      },
      visit_id(){
        let id = this.$store.getters['catalog/visit_id']
        if(!Array.isArray(id)) id = [id]
        return id
      },
      specimen_type_id(){
        let id = this.$store.getters['catalog/specimen_type_id']
        if(!Array.isArray(id)) id = [id]
        return id
      },
      dictionary() {
        return this.$store.getters['catalog/dictionary']
      },
      cart_title() {
        return "Specimen Cart (" + this.cart.length + ")"
      },
      cart() {
        return this.$store.getters['catalog/cart']
      },


      show: {
        get() {
          return this.$store.getters.cartModal
        },
        set(value) {
          this.$store.commit('cartModal', value)
        }
      },
      subjects() {
        return this.cart.map(x => x[this.subject_id]).filter((x,i,s) => s.indexOf(x) === i)
      },
      visits() {
        return this.cart.map(x => x[this.visit_id]).filter((x,i,s) => s.indexOf(x) === i)
      },
      specimen_types() {
        return this.cart.map(x => x[this.specimen_type_id]).filter((x,i,s) => s.indexOf(x) === i)
      }
    },
    methods: {
      downloadCart() {
        this.$store.dispatch('catalog/downloadCart')
      }
    }
  }
</script>

<style>

</style>