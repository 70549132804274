<template>
  <span id = "loading">
  <v-row justify="center" id = "loadingrow">
    <v-dialog
      v-model = "show"
      width = "250"
      persistent
      hide-overlay
      id = "loading-dialog"
    >
      <v-card 
        flat 
        height = "250"
        width = "250"
        style = "overflow: hidden; text-align: center"
      >
        <v-card-text class = "ma-0 pa-0">

            <v-progress-circular
            :indeterminate = "true"
            :size = "200"
            :width = "15"
            color = "primary"
            style = "margin-top: 25px;"
            ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>  
</span>
</template>

<script>
  export default {
    name: "iugbLoading",
    computed: {
      show() {
        return this.$store.getters['auth/logged_in'] && this.$store.getters['catalog/report_id'] && !this.$store.getters['catalog/ready']
      }
    }
  }
</script>

<style>

  #loading .v-dialog {
    box-shadow: none;
  }

</style>