<template>
  <v-select
    v-model = "filter_value"
    :items = "field_values"
    multiple
    hide-details
    chips
    clearable
    deletable-chips
    dense
    placeholder="Filter"
  ></v-select> 
</template>

<script>

  export default {
    name: "enumFilter",
    props: {
      value: {
        required: true
      },
      field: {
        type: Object,
        required: true
      }
    },
    data() {
      return {

      }
    },
    computed: {
      field_values() {
        if(Array.isArray(this.field.values)) {
          return this.field.values
        } else {
          return [this.field.values]
        }
      },
      filter_value: {
        get() {
          return this.value
        },
        set(v) {
          if(v.length === 0) v = null
          this.$emit('input', v)
        }
      }
    },
    mounted() {
      this.filter_value = ""
    }
  }

</script>