<template>
  <span>
  <v-row justify="center">
    <v-dialog
      v-model = "show"
      width = "750px"
      scrollable
    >
      <v-card>
        <iugb-card-title
          title="Privacy Policy"
          icon ="mdi-eye-check"
          color = "primary"
          showClose
          @close = "show = false"
        ></iugb-card-title>
        <v-card-text class="mt-4">
          Privacy Policy
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="show = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>  
</span>
</template>

<script>
  export default {
    name: "privacyModal",
    computed: {
      show: {
        get() {
          return this.$store.getters.privacyModal
        },
        set(value) {
          this.$store.commit('privacyModal', value)
        }
      }
    },
  }
</script>

<style>

</style>