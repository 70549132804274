<template>
    <v-app-bar app clipped-left id="appbar" flat color="primary" dark>
      <v-app-bar-nav-icon @click="sidebarVisible = !sidebarVisible" v-if="ready"></v-app-bar-nav-icon>
      <v-toolbar-title>{{catalog_name}}</v-toolbar-title>
      <v-btn icon small @click="switchCatalog" class="ml-2" v-if="showSwitch">
        <v-icon>mdi-swap-horizontal</v-icon>
      </v-btn>
      <v-toolbar-items class = "ml-4" v-if="ready"> 
        <template v-for="(button,i) in buttons">
          <v-btn v-if="button.action" text :key="i" @click="button.action" :disabled="button.disabled || false">
            <v-icon class = "mr-1">{{ button.icon }}</v-icon>
            {{ button.text }}
            <div class="badge" v-text="button.badge_value" v-if="button.badge"></div>
          </v-btn>
          <v-menu v-else-if="button.actions" :key="i" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                :disabled="button.disabled || false"
              >
                <span>
                  <v-icon class = "mr-1">{{ button.icon }}</v-icon>
                  <div class="badge" v-text="button.badge_value" v-if="button.badge"></div>                
                </span>
                {{ button.text}}
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group>
                <v-list-item
                  v-for="(item, index) in button.actions"
                  :key="index"
                  :disabled="item.disabled || false"
                  @click="item.action"
                >
                  <v-list-item-icon class="mr-3" v-if="item.icon"><v-icon>{{ item.icon }}</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <span v-if="logged_in">
        Welcome {{ userDisplayName }}!
        <v-btn icon @click = "logout">
          <v-icon>mdi-logout</v-icon>
        </v-btn>      
      </span>
      <v-dialog v-model="confirmCartClear" width="500">
        <v-card>
        <iugb-card-title
          title="Are you sure?"
          icon ="mdi-cart-remove"
          color = "primary"
        ></iugb-card-title>
          <v-card-text class="pt-9">
            <p class="text-h6">Are you sure you want to clear your cart?</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.native="confirmCartClear=false" color="primary">Cancel</v-btn>
            <v-btn @click="clearCart" text>Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
</template>

<script>

export default {
  name: "iugbHeader",
  props:{
    drawer: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      confirmCartClear: false,
    };
  },
  computed: {
    buttons() {
      return [
        {
          text: 'Dictionary',
          icon: 'mdi-book',
          action: this.showDictionary
        },
        {
          text: 'Join',
          icon: 'mdi-set-left-center',
          action: this.showJoin,
          disabled: true,
        },
        {
          text: "Cart",
          icon: "mdi-cart",
          badge: this.cart_count > 0,
          badge_value: this.cart_count,
          actions: [
            {
              text: "Add Selected",
              icon: "mdi-cart-plus",
              action: this.addSelectedToCart,
            },
            {
              text: "Remove Selected",
              icon: "mdi-cart-minus",
              action: this.removeSelectedFromCart,
            },
            {
              text: "Clear Cart",
              icon: "mdi-cart-remove",
              action: this.showCartClearConfirm,
              disabled: this.cart_count == 0,
            },
            {
              text: "View Cart",
              icon: "mdi-cart-outline",
              action: this.showCart,
              disabled: this.cart_count == 0,
            }
          ]
        },
        // {
        //   text: "Select",
        //   icon: 'mdi-format-list-bulleted-square',
        //   actions: [
        //     {
        //       icon: 'mdi-playlist-check',
        //       text: "Select All",
        //       action: this.selectAll
        //     },
        //     {
        //       icon: 'mdi-playlist-remove',
        //       text: "Select None",
        //       action: this.selectNone
        //     },
        //     {
        //       icon: 'mdi-playlist-minus',
        //       text: "Invert Selection",
        //       action: this.invertSelection
        //     },
        //     {
        //       icon: 'mdi-cart-plus',
        //       text: "Add Selected to Cart",
        //       action: this.addSelectedToCart
        //     }
        //   ]
        // },
        {
          text: "Download",
          icon: 'mdi-download',
          actions: [
            {
              text: "Download All",
              action: this.downloadAll,
              icon: 'mdi-playlist-check',
            },
            {
              text: "Download Filtered",
              action: this.downloadFiltered,
              icon: "mdi-filter-check",
            },
            {
              text: "Download Cart",
              action: this.downloadCart,
              icon: "mdi-cart-check",
            },
          ]
        },
        {
          text: "Help",
          icon: 'mdi-help-circle',
          action: this.showHelp,
          disabled: false
        },
        // {
        //   text: "Settings",
        //   icon: 'mdi-cog',
        //   action: this.showSettings,
        //   disabled: true
        // },
        // {
        //   text: "Stats",
        //   icon: 'mdi-chart-bar',
        //   action: this.showStats,
        //   disabled: true,
        // }
      ]
    },
    cart_count() {
      return this.$store.getters['catalog/cartCount'];
    },
    ready() {
      //return true
      return this.$store.getters.ready;
    },
    logged_in() {
      return this.$store.getters['auth/logged_in'];
    },
    userDisplayName() {
      return this.$store.getters['auth/full_name']
    },
    catalog_name() {
      return this.$store.getters['catalog/name']
    },
    sidebarVisible: {
      get() {
        return this.$store.getters.drawer
      },
      set(v) {
        this.$store.commit('drawer', v)
      }
    },
    showSwitch() {
      return this.$store.getters['auth/catalogs'].length > 1 && this.logged_in
    },  
  },
  methods: {
    showCartClearConfirm() {
      this.confirmCartClear = true;
    },
    addSelectedToCart() {
      return this.$store.dispatch("catalog/addSelectedToCart");
    },
    removeSelectedFromCart() {
      return this.$store.dispatch("catalog/removeSelectedFromCart");
    },
    clearCart() {
      this.confirmCartClear = false;
      return this.$store.commit("catalog/clearCart");
    },
    showCart() {
      return this.$store.commit("cartModal", true)
    },
    switchCatalog() {
      this.$store.commit('catalogPickerModal', true)
    },
    showDictionary() {
      this.$store.commit('dictionaryModal', true)
    },
    showJoin() {
      this.$store.commit('joinModal', true)
    },
    showHelp() {
      this.$store.commit('helpModal', true)
    },
    showSettings() {
      this.$store.commit('settingsModal', true)
    },
    showStats() {
      this.$store.commit('statsModal', true)
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
    selectAll() {
      this.$store.getters['catalog/tabulator'].selectRow("all");
    },
    selectNone() {
      this.$store.getters['catalog/tabulator'].deselectRow();
    },
    invertSelection() {
      let tab = this.$store.getters['catalog/tabulator']
      let selected = tab.getSelectedRows()
      tab.selectRow("all")
      tab.deselectRow(selected)
    },
    downloadAll() {
      // let file_name = `${this.catalog_name}_all_${new Date().toLocaleDateString()}.csv`
      this.$store.dispatch('catalog/downloadAll')
      // this.$store.getters['catalog/tabulator'].download("csv", file_name, {}, "all");
    },
    downloadFiltered() {
      // let file_name = `${this.catalog_name}_filtered_${new Date().toLocaleDateString()}.csv`
      this.$store.dispatch('catalog/downloadFiltered')
      // this.$store.getters['catalog/tabulator'].download("csv", file_name, {});
    },
    downloadCart() {
      // let file_name = `${this.catalog_name}_cart_${new Date().toLocaleDateString()}.csv`
      this.$store.dispatch('catalog/downloadCart')
      // this.$store.getters['catalog/tabulator'].download("csv", file_name, {}, "selected");
    }
  }
}
</script>

<style scoped>

  .badge {
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 25px;
    font-size: 12px;
    background: #888;
    color: #fff;
    border-radius: 10px;
    padding: 1px 3px;
    vertical-align: top;
    margin-left: -15px;
    margin-top: 15px;
    z-index: 1000;
  }

</style>