<template>
  <v-snackbar
    top
    v-model="show"
    app
    :color = "color"
    :light = "light"
  >
    <v-icon left v-if="icon">{{icon}}</v-icon>
    <span v-html="message"></span>
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="show=false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "iugbSnackbar",
  data() {
    return {
      snackbar: true,
      text: "Testing"
    }
  },
  computed: {
    message() {
      return this.$store.getters["message/message"]
    },
    icon() {
      return this.$store.getters["message/icon"]
    },
    color() {
      return this.$store.getters["message/color"]
    },
    light() {
      return this.$store.getters["message/light"]
    },
    show: {
      get() {
        return this.$store.getters["message/visible"]
      },
      set(v) {
        this.$store.commit("message/visible", v)
      }
    }
  },
  methods: {

  }
}
</script>