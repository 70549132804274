var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"width":"700","persistent":"","scrollable":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('iugb-card-title',{attrs:{"title":("Select a Catalog (" + (_vm.catalogs.length) + " Available)"),"subtitle":"Select a catalog","icon":"mdi-table-large","color":"primary","showClose":_vm.catalogLoaded},on:{"close":function($event){_vm.show = false}}}),_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.all_catalogs ? _vm.all_catalogs.length > 6 : false),expression:"all_catalogs ? all_catalogs.length > 6 : false"}],staticClass:"ma-1 mt-2",attrs:{"dense":"","label":"Catalog Search","outlined":"","prepend-inner-icon":"mdi-magnify","hide-details":"","clearable":""},model:{value:(_vm.searchField),callback:function ($$v) {_vm.searchField=$$v},expression:"searchField"}}),_c('v-list',{staticClass:"ma-0 pa-0"},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.catalog),callback:function ($$v) {_vm.catalog=$$v},expression:"catalog"}},_vm._l((_vm.catalogs),function(catalog,index){return _c('v-list-item',{key:index,attrs:{"value":catalog.name}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(catalog.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(catalog.description || "No Description Available"))])],1),_c('v-list-item-action',[_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({staticClass:"ma-0 pa-0 px-1",staticStyle:{"text-align":"center","border-right":"solid #CCC 1px"}},'v-col',attrs,false),on),[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-table-large")]),_c('br'),_c('span',{staticClass:"badge",domProps:{"textContent":_vm._s((catalog.Metadatum.total_records * 1).toLocaleString())}})],1)]}}],null,true)},[_c('span',[_vm._v("Records in Catalog")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({staticClass:"ma-0 pa-0 px-1",staticStyle:{"text-align":"center","border-right":"solid #CCC 1px"}},'v-col',attrs,false),on),[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-account")]),_c('br'),_c('span',{staticClass:"badge",domProps:{"textContent":_vm._s((catalog.Metadatum.unique_subjects * 1).toLocaleString())}})],1)]}}],null,true)},[_c('span',[_vm._v("Subjects in Catalog")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({staticClass:"ma-0 pa-0 px-1",staticStyle:{"text-align":"center","border-right":"solid #CCC 1px"}},'v-col',attrs,false),on),[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-calendar")]),_c('br'),_c('span',{staticClass:"badge",domProps:{"textContent":_vm._s((catalog.Metadatum.unique_visits * 1).toLocaleString())}})],1)]}}],null,true)},[_c('span',[_vm._v("Visits in Catalog")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-col',_vm._g(_vm._b({staticClass:"ma-0 pa-0",staticStyle:{"text-align":"center"}},'v-col',attrs,false),on),[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-test-tube")]),_c('br'),_c('span',{staticClass:"badge",domProps:{"textContent":_vm._s((catalog.Metadatum.unique_specimen_types * 1).toLocaleString())}})],1)]}}],null,true)},[_c('span',[_vm._v("Specimen Types in Catalog")])])],1)],1)],1)],1)}),1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.catalogLoaded)?_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.show=false}}},[_vm._v(" Cancel ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.catalog},on:{"click":_vm.setCatalog}},[_vm._v(" Select ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }