<template>
  <v-expansion-panel>
    <v-expansion-panel-header
      :disable-icon-rotate="true"
      expand-icon="mdi-filter"
      class = "pa-2"
    >
      <v-checkbox
        :label = "field.catalog_id"
        v-model = "visible"
        class = "ma-0"
        hide-details
        @click.stop=""
      ></v-checkbox>
      <template v-slot:actions>
        <v-icon
          @click.stop="resetFilter"
          v-if="filter_value !== null"
        >mdi-arrow-u-left-top-bold</v-icon>
        <v-icon>mdi-filter</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <component 
        :is="componentName"
        v-model = "filter_value"
        :field = "field"
      ></component>
      <v-checkbox
        v-model = "remove_missing"
        label = "Remove missing"
        v-show = "any_missing"
        hide-details
      ></v-checkbox>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  export default {
    name: "fieldController",
    props: {
      value: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        filter_value: null,
        remove_missing: false
      }
    },
    mounted() {

    },
    computed: {
      field() {
        return this.value
      },
      field_type() {
        return this.field.type
      },
      componentName() {
        let x = `${this.value.type}Filter`
        return x
      },
      any_missing() {
        return this.field.non_na_count !== this.field.total_count
      },
      tabulator() {
        try {
          if(this.$store.getters['catalog/ready']) {
            return this.$store.getters['catalog/tabulator'];
          } else {
            return null
          }
        } catch (e) {
          return null
        }
      },
      visible: {
        get() {
          try {
            if(this.tabulator !== null) {
              return this.tabulator.getColumn(this.field.catalog_id).isVisible() || false
            } else {
              return false
            }
          } catch(e) {
            return false
          }
        },
        set(v) {
          if(this.$store.getters["catalog/ready"]) {
            if(v) {
              this.tabulator.showColumn(this.field.catalog_id);
            } else {
              this.tabulator.hideColumn(this.field.catalog_id);
            }
          }
        }
      }
    },
    methods: {
      resetFilter() {
        this.filter_value = null
        this.remove_missing = false
      },
      applyFilter(v) {
        this.$store.commit("catalog/filter", {
          field: this.field.catalog_id,
          type: this.field.type,
          value: v.filter_value,
          remove_missing: v.remove_missing
        })
      }
    },
    watch: {
      filter_value: {
        handler(v) {
          this.applyFilter({filter_value: v, remove_missing: this.remove_missing})
        }
      },
      remove_missing: {
        handler(v) {
          this.applyFilter({filter_value: this.filter_value, remove_missing: v})
        },
      }
    }
  }

</script>