<template>
  <v-app id="iugbCatalogs" style = "overflow: hidden">
    <iugb-sidebar></iugb-sidebar>
    <iugb-header></iugb-header>
    <router-view></router-view>
    <iugb-login></iugb-login>
    <iugb-catalog-picker></iugb-catalog-picker>
    <iugb-loading></iugb-loading>
    <iugb-footer></iugb-footer>
    <dictionary-modal></dictionary-modal>
    <join-modal></join-modal>
    <help-modal></help-modal>
    <settings-modal></settings-modal>
    <cart-modal></cart-modal>
    <stats-modal :data="catalogData"></stats-modal>
    <iugb-snackbar></iugb-snackbar>
    <privacy-modal></privacy-modal>
    <disclaimer-modal></disclaimer-modal>
  </v-app>
</template>

<script>

  export default {
    name: 'iugbCatalogs',
    data: () => ({

    }),
    async mounted() {
      this.$store.dispatch("auth/checkToken");
    },
    computed: {
      loggedIn() {
        return this.$store.getters['auth/logged_in']
      },
      catalogData() {
        try {
          return this.$store.getters['catalog/tabulator'].getData()
        } catch (e) {
          return []
        }
      }
    },

    watch: {
      loggedIn(v) {
        if(v) {
          if(this.$store.getters['auth/catalogs'].length > 1) {
            if(this.$route.params.report_id) {
              this.$store.dispatch('catalog/load', {report_id: this.$route.params.report_id})
            } else {
              this.$store.commit('catalogPickerModal', true)
            }
          } else if(this.$store.getters['auth/catalogs'].length == 1) {
            this.$store.dispatch("catalog/load", {report_id: this.$store.getters['auth/catalogs'][0].report_id})            
          } else {
            this.$store.dispatch("message/error", "You do not have access to any catalogs at this time.")
          }
        }
      }
    }
  };

</script>

<style lang="scss">

  html {
    overflow-y: hidden !important;
  }

</style>