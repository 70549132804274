<template>
  <v-main>
    <iugb-tabulator 
      :tabulator.sync = "tabulator"
      :options = "options" 
      :data = "url"
      :column_defs = "columns"
      :tableHeight = "tableHeight"
      :render = "ready"
      :filters = "filters"
      v-if="logged_in"
    ></iugb-tabulator>
  </v-main>
</template>

<script>

  export default {
    name: "iugbMain",
    data() {
      return {
        tableHeight: window.innerHeight,
      };
    },
    computed: {
      filters() {
        return this.$store.getters["catalog/filters"]
      },
      logged_in() {
        return this.$store.getters['auth/logged_in'];
      },
      ready() {
        return this.$store.getters.ready
      },
      tabulator: {
        get() {
          return this.$store.getters['catalog/tabulator']
        },
        set(v) {
          this.$store.commit("catalog/tabulator", v)
        }
      },
      url() {
        return this.$store.getters['catalog/catalog_url']
      },
      columns() {
        return this.$store.getters['catalog/columns']
      },
      options() {
        let options = this.$store.getters['catalog/options']
        options.ajaxConfig = {
          headers: {
            "Authorization": this.$store.getters['auth/token']
          }
        }
        return options
      },
      name() {
        return this.$store.getters['catalog/report_id']
      }
    },
    methods: {
      getTableHeight() {
        let barheights
        try {
          barheights = document.getElementById("appbar").scrollHeight + document.getElementById("footer").scrollHeight;
        } catch(e) {
          barheights = 100;
        }
        return window.innerHeight - barheights;      
      },
    },
    mounted() {
      this.tableHeight = this.getTableHeight()
      window.addEventListener('resize', () => {
        this.$nextTick(() => {
          this.tableHeight = this.getTableHeight()
        })
      })
    },
    watch: {

    },
  }
</script>

<style lang = "scss">


</style>