<template>
  <v-card>
    <iugb-card-title
      title="Field Explorer"
      icon ="mdi-chart-bar"
      color = "primary"
    ></iugb-card-title>
    <v-card-text>
      <v-select
        v-model = "field"
        :items = "fields"
        label = "Select Field"
        outlined
        dense
      ></v-select>
      <apexchart
        :type = "chartType"
        width="100%"
        :options = "options"
        :series = "series"
      ></apexchart>
    </v-card-text>
  </v-card>    
</template>

<script>
export default {
  name: "fieldExplorer",
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      field: "",
      binCount: 10,
    }
  },
  computed: {
    dictionary() {
      return this.$store.getters['catalog/dictionary'];
    },
    fields() {
      return this.dictionary.map(d => d.catalog_id)
    },
    binSize() {
      let f = this.field_values
      let min = Math.min(...f)
      let max = Math.max(...f)
      let range = max - min
      let binSize = (range / this.binCount).toPrecision(1)
      return binSize * 1
    },
    binLabels() {
      let f = this.field_values
      let min = Math.min(...f)
      let labels = []
      for (let i = 0; i < this.binCount; i++) {
        labels.push(min + (i * this.binSize))
      }
      return labels
    },
    binnedData() {
      let f = this.field_values
      let min = Math.min(...f)
      let bins = []
      f.forEach(v => {
        let bin = Math.floor((v - min) / this.binSize)
        if (bins[bin]) {
          bins[bin]++
        } else {
          bins[bin] = 1
        }
      })
      return bins
    },
    field_values() {
      return this.data.map(d => {
        let x = d[this.field]
        if(x == undefined) return x
        let n = x * 1
        if(Object.is(n, NaN)){
          return x
        } else {
          return n
        }
      }).filter(x => x != undefined)
    },
    unique_values() {      
      return this.field_values.filter((v, i, a) => a.indexOf(v) === i).sort();
    },
    dataType() {
      if(typeof this.unique_values[0] === "number") {
        return 'numeric'
      } else {
        return 'categorical'
      }       
    },
    chartType() {
      if(this.dataType == "numeric") {
        return "histogram"
      } else {
        return "bar"
      }
    },
    options() {
    
      let xaxis
      
      if(this.dataType == "numeric") {
        xaxis = {
          type: "numeric",
          tickPlacement: "on",
        }
      } else {
        xaxis = {
          categories: this.unique_values,
          type: "category"
        }
      }

      let options = {
        chart: {},
        xaxis: xaxis,
      }

      return options
    },
    series() {
      if(this.dataType == "categorical") {
        let f = this.field_values
        let u = this.unique_values
        if(u.length > 0) {
            let s = Array.apply(0, Array(u.length)).map(() => 0)
            f.forEach((v) => {
                if(v !== undefined) {
                let index = u.indexOf(v)
                s[index] = s[index] + 1            
                }
            })
            let series = [{
                name: this.field,
                data: s
            }]
            return series          
        }
        return []
      } else {
        let series = [{
          name: this.field,
          data: this.binnedData
        }]
        return series
      }

    },
  }
}
</script>