<template>
  <v-tab-item>
      <v-card flat>
      <v-card-text>
          <v-simple-table dense>
          <thead>
              <tr>
              <th></th>
              <th v-for="(f, i) in ids.concat(['Specimens'])" :key="i">{{f}}</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="(row, i) in records" :key="i">
              <td>
                  <v-btn small icon @click="removeRecord(row)">
                  <v-icon small>mdi-minus</v-icon>
                  </v-btn>
              </td>
              <td v-for="(f, i) in ids.concat(['Specimens'])" :key="i">{{row[f]}}</td>
              </tr>
          </tbody>
          </v-simple-table>
      </v-card-text>
      </v-card>
  </v-tab-item>    
</template>

<script>
  export default {
    name: 'CartTab',
    props: {
      name: {
        type: String,
        required: true,
      },
      ids: {
        type: Array,
        required: true,
      },
      cart: {
        type: Array,
        required: true,
      },
      dictionary: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {}
    },
    computed: {
   
      records() {
        let ids = this.ids

        let unique_values = this.cart.map(x => {
          return ids.map(id => x[id] ).join(" - ")
        }).filter((x,i,s) => s.indexOf(x) === i)
        // } else {
        //   let unique_specimen_types = this.cart.map(x => x[this.specimen_type_id]).filter((x,i,s) => s.indexOf(x) === i)
        // }
        let values = []
        for (let value of unique_values) {
          let data = this.cart.filter(x => {
            let id = ids.map(id => x[id] ).join(" - ")
            return id === value
          })
          let x = {}
          ids.forEach(id => x[id] = data[0][id])
          x[ids.join(" - ")] = value
          x["Specimens"] = data.length
          values.push(x)
        }
        return values
      },
    },
    methods: {
      removeRecord(type) {
        let rows = this.cart.filter(x => {
          let keep = true
          this.ids.forEach(id => {
            if(x[id] !== type[id]) {
              keep = false
            }
          })
          return keep
        })
        this.$store.commit('catalog/removeFromCart', rows)
      },
    }
  }
</script>