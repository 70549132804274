<template>
  <v-text-field
    v-model = "filter_value"
    hide-details
    placeholder="Filter"
    dense
  ></v-text-field>
</template>

<script>

  export default {
    name: "stringFilter",
    props: {
      value: {
        required: true
      },
      field: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        timeout: null
      }
    },
    computed: {
      filter_value: {
        get() {
          return this.value
        },
        set(v) {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            if(v == "") {
              this.$emit('input', null)
            } else {
              this.$emit('input', v)
            }
          }, 250)
        }
      }
    },
    mounted() {
      this.filter_value = ""
    }
  }

</script>