import Vue from "vue";

function downloadFile(file) {
  // Create a link and set the URL using `createObjectURL`
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = URL.createObjectURL(file);
  link.download = file.name;

  // It needs to be added to the DOM so it can be clicked
  document.body.appendChild(link);
  link.click();

  // To make this work on Firefox we need to wait
  // a little while before removing it.
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    link.parentNode.removeChild(link);
  }, 0);
}

let state = {
  report_id: null,
  specimen_id: null,
  subject_id: null,
  visit_id: null,
  specimen_type_id: null,
  specimen_quantity_id: null,
  specimen_count_id: null,
  quantity_units_id: null,
  tabulator: {},
  dictionary: [],
  config: {},
  meta: {},
  filteredCount: 0,
  options: {
    paginationSize: 500,
    progressiveLoad: "scroll",
    filterMode: "remote",
    progressiveLoadScrollMargin: 300,
    sortMode: "remote",
    layout: "fitColumns",
    reactiveData: false,
    responsiveLayout: true,
    selectable: true,
    layoutColumnsOnNewData: true,
    selectableRangeMode: "click"
  },
  cart: []
}

let mutations = {}
let getters = {}

// Builds basic mutations/getters for each item in state
// These can be overridden by making custom mutation/getter
// below this blck
Object.keys(state).forEach(key => {
    
    mutations[key] = (state, payload) => {
      state[key] = payload
    }
    getters[key] = state => {
      return state[key]
    }

});

// Set a specific option for tabulator
mutations.option = (state, payload) => {
  state.options[payload.key] = payload.value
}

// Build the dynamic options object for tabulator
getters.options = (state) => {

  let config = {}
  let options = state.options

  if(Object.keys(state.config).indexOf('display') > -1) {
    let conf = {}
    let option_keys = Object.keys(state.options)
    Object.keys(state.config.display).forEach(key => {
      if(option_keys.indexOf(key) > -1) {
        conf[key] = options[key]
      }
    })
    config = conf
  } else {
    config = {}
  }

  let opts = Object.assign({}, options, config)

  return opts

}

mutations.filter = (state, payload) => {
  let i = state.dictionary.findIndex(field => {
    return field.catalog_id == payload.field
  })
  if(payload.value == null && payload.remove_missing == false) {
    payload = null
  } 
  let field = state.dictionary[i]
  field.filter = payload
}

getters.filters = state => {
  let filters = state.dictionary.map(field => field.filter).filter(x => x !== null)
  if(filters.length === 0) filters = null
  return filters
}

mutations.addToCart = (state, payload) => {
  if(Array.isArray(payload) === false) payload = [payload]
  let current_cart = state.cart.map(x => x[state.specimen_id])
  payload.map(row => row.getData()).forEach(sample => {
    if(current_cart.indexOf(sample[state.specimen_id]) === -1) {
      state.cart.push(sample)
    }
  })
  try {
    payload.forEach(row => row.reformat())
  } catch(e) {
    let ids = payload.map(r => r[state.specimen_id])
    ids
    let rows = eval(`state.tabulator.searchRows("${state.specimen_id}", "in", ids)`)
    rows.forEach(row => row.reformat())
  }
}

mutations.removeFromCart = (state, payload) => {
  if(Array.isArray(payload) === false) payload = [payload]
  let ids = payload.map(x => {
    try{
      return x.getData()[state.specimen_id]
    } catch(e) {
      return x[state.specimen_id]
    }
  })
  state.cart = state.cart.filter(x => ids.indexOf(x[state.specimen_id]) === -1)
  try {
    payload.forEach(row => row.reformat())
  } catch(e) {
    let ids = payload.map(r => r[state.specimen_id])
    ids
    let rows = eval(`state.tabulator.searchRows("${state.specimen_id}", "in", ids)`)
    rows.forEach(row => row.reformat())
  }
}

mutations.clearCart = state => {
  state.cart = []
  state.tabulator.getRows("all").forEach(row => row.reformat())
}

getters.cartCount = state => {
  return state.cart.length
}

mutations.meta = (state, payload) => {
  state.meta = payload
}

getters.meta = state => {
  return state.meta
}

// Custom dictionary mutation to convert from object to array
mutations.dictionary = (state, payload) => {
  if(payload.length > 0) {
    let dictionary = payload.map(f => {
      f.visible = f.default || false
      f.filter = null
      if(f.source === "_sample_id") {
        f.visible = true
      }    
      return f
    })
    // let dictionary = []
    // Object.keys(payload).forEach(key => {
    //   let f = payload[key]
    //   f.visible = f.default || false
    //   f.filter = null
    //   f.name = key
    //   if(f.source === "_sample_id") {
    //     f.visible = true
    //   }
    //   dictionary.push(f)
    // })
    if(dictionary !== undefined) {
      state.dictionary = dictionary    
    }
  }

}

// Getter to build the columns object for the tabulator
getters.columns = state => {
  let columns =  state.dictionary.map(field => {
    let headerContextMenu = [
      {
        label: `Hide ${field.catalog_id}`,
        action: (e, column) =>{
          state.tabulator.hideColumn(column)
          // alert("hide column")
        }
      }
    ]    
    // if(field.type == "select") {
    //   field.unique_values.forEach(value => {
    //     headerContextMenu.push({
    //       label: `Show Only ${value}`,
    //       action: (e, column) =>{
            
    //         // alert("show column")
    //       }
    //     })      
    //   })

    // }
    return {
      title: field.catalog_id,
      field: field.catalog_id,
      visible: field.visible,
      formatter: field.formatter || 'html',
      headerContextMenu: headerContextMenu,
    }
  })
  return columns
}

getters.cart_ids = state => {
  return state.cart.map(x => x[state.specimen_id])
}

getters.totalCount = state => {
  // return state.config.totalRecords
  return state.meta.total_records
}

// getters.filteredCount = state => {
//   return state.tabulator.getDataCount("active")
// }

getters.selectedCount = state => {
  return state.cart.length
}

// Getter to calculate whether the table is ready to be interacted with
getters.ready = (state, getters) => {
  state, getters
  let config_ready = Object.keys(state.config).length > 0
  // let tabulator_ready = Object.keys(state.tabulator).length > 0
  let columns_ready = getters.columns.length > 0
  let ready = (config_ready && columns_ready)
  return ready
  // return true
}

// Getter to build the array of objects that populate the sidebar 
getters.fields = state => {
  return state.dictionary.map(field => {
    try {
      field.visible = state.tabulator.getColumn(field.catalog_id).isVisible()
    } catch(e) {
      e
    }
    return field
  })
}

getters.name = state => {
  return state.config.name || "IUGB Catalogs"
}

getters.report_id = state => {
  return state.config.report_id || null
}

getters.catalog_url = state => {
  return `${process.env.VUE_APP_API_URL}catalogs/${state.report_id}`
}

let actions = {
  async load({commit}, payload) {
    if(payload) {
      commit("report_id", payload.report_id)
      let dictionary = await Vue.$fetch.get(`catalogs/${payload.report_id}/dictionary`)
      commit('dictionary', dictionary);
      let config = await Vue.$fetch.get(`catalogs/${payload.report_id}/config`)
      commit('config', config);
      let meta = await Vue.$fetch.get(`catalogs/${payload.report_id}/meta`)
      commit('meta', meta);      
      commit('cart', []);
      let ids = ['specimen', 'subject', 'visit', 'specimen_type', 'specimen_quantity', 'specimen_count', 'quantity_units']
      ids.forEach(id => {
        let n = `${id}_id`
        commit(n, state.dictionary.filter(x => x.identifier === id).map(x => x.catalog_id))
      })
    }
  },
  async unload({commit}) {
    commit("report_id", null)
    commit("dictionary", [])
    commit("config", {})
    commit("tabulator", {})
    commit("meta", [])
  },
  addSelectedToCart({commit}) {
    let selected = state.tabulator.getRows('selected')
    commit("addToCart", selected)
    selected.forEach(row => state.tabulator.deselectRow(row))
  },
  removeSelectedFromCart({commit}) {
    let selected = state.tabulator.getRows('selected')
    commit("removeFromCart", selected)
    selected.forEach(row => state.tabulator.deselectRow(row))
  },
  async downloadAll({state}) {
    let file_name = `${state.report_id}_all_${new Date().toLocaleDateString()}.csv`
    let catalog = await Vue.$fetch.get(`catalogs/${ state.report_id}?type=csv`, "text")
    let file = new File([catalog], file_name, {type: "text/csv;charset=utf-8"});
    downloadFile(file)
  },
  async downloadFiltered({state}) {
    let file_name = `${state.report_id}_filtered_${new Date().toLocaleDateString()}.csv`
    let filter = JSON.stringify(state.tabulator.getFilters())
    let catalog = await Vue.$fetch.get(`catalogs/${ state.report_id}?type=csv&filter=${filter}`, "text")
    let file = new File([catalog], file_name, {type: "text/csv;charset=utf-8"});
    downloadFile(file)
  },
  async downloadCart({state}) {
    let file_name = `${state.report_id}_cart_${new Date().toLocaleDateString()}.csv`
    let ctlg = state.cart;
    let header = Object.keys(ctlg[0])
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    let rows = ctlg.map(r => header.map(f => JSON.stringify(r[f], replacer)).join(','))
    let csv = [
      header.join(","),
      ...rows
    ].join("\n")
    let file = new File([csv], file_name, {type: "text/csv;charset=utf-8"});
    downloadFile(file)
  }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}