<template>
  <v-row justify="center">
    <v-dialog
      v-model = "show"
      width = "900px"
      scrollable
    >
      <v-card>
        <iugb-card-title
          title="Help"
          icon ="mdi-help-circle"
          color = "primary"
          showClose
          @close = "show = false"
        ></iugb-card-title>
        <v-tabs
          v-model="tab"
          centered
          grow
          color="primary"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab>
            Introduction
          </v-tab>
          <v-tab>
            Filtering
          </v-tab>
          <v-tab>
            Sorting
          </v-tab>
          <v-tab>
            Cart
          </v-tab>
        </v-tabs>         
        <v-card-text class="ma-0 px-0">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat>
                <v-card-text v-html="intro"></v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text v-html="filtering"></v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text v-html="sorting"></v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text v-html="ordering"></v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="show = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>  
</template>

<script>

  import { marked } from 'marked';

  export default {
    name: "helpModal",
    data() {
      return {
        tab: 0,
        intro: "",
        filtering: "",
        sorting: "",
        ordering: "",
      }
    },
    computed: {
      dictionary() {
        return this.$store.getters['catalog/dictionary']
      },
      show: {
        get() {
          return this.$store.getters.helpModal
        },
        set(value) {
          this.$store.commit('helpModal', value)
        }
      },
    },
    async mounted() {
      this.intro = await this.loadMarkdown("http://localhost:3200/assets/markdown/general.md")
      this.filtering = await this.loadMarkdown("http://localhost:3200/assets/markdown/filtering.md")
      this.sorting = await this.loadMarkdown("http://localhost:3200/assets/markdown/joining.md")
      this.ordering = await this.loadMarkdown("http://localhost:3200/assets/markdown/ordering.md")
    },
    methods: {
      async loadMarkdown(url) {

        let md = await (await fetch(url)).text()
        let html = await marked.parse(md)

        return html

      },
    }
  }
</script>

<style>

</style>