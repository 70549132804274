<template>
  <v-row justify="center">
    <v-dialog
      v-model = "visible"
      width = "500"
      persistent
    >
      <v-card>
        <iugb-card-title
          title="Login"
          subtitle="Please enter your username and password to continue"
          icon ="mdi-key"
          color = "primary"
        ></iugb-card-title>
        <v-card-text
          class="mt-2"
        >
          <v-form
            ref = "form"
            v-model = "valid"
          >
            <v-text-field
              v-model = "username"
              ref="username"
              :rules = "usernameRules"
              label = "Username"
              required
            ></v-text-field>
            <v-text-field
              v-model = "password"
              :rules = "passwordRules"
              label = "Password"
              type = "password"
              @keypress="checkLogin"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color = "primary"
            text
            @click = "login"
            :disabled = "!valid"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'iugbLogin',
    props: {
    },
    data() {
      return {
        valid: false,
        username: '',
        password: '',
        usernameRules: [],
        passwordRules: [],
      }
    },
    computed: {
      visible() {
        return !this.$store.getters['auth/logged_in'];
      },
    },
    methods: {
      checkLogin(e) {
        if (e.keyCode === 13) {
          this.login();
        }
      },
      login() {
        this.$store.dispatch('auth/login', {
          username: this.username,
          password: this.password,
        })
      }
    },
    mounted() {
      if(this.visible) this.$nextTick(() => {
        this.$refs.username.focus()
      })
    },
    watch: {
      visible(v) {
        if(v) this.$nextTick(() => this.$refs.username.focus())
      }
    }
  }
</script>
