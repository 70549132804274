<template>
  <span>
  <v-row justify="center">
    <v-dialog
      v-model = "show"
      scrollable
      fullscreen
    >
      <v-card>
        <iugb-card-title
          :title="title"
          icon ="mdi-chart-bar"
          color = "primary"
          showClose
          @close = "show = false"
        ></iugb-card-title>
        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols = 3>
                <info-card
                  color = "orange"
                  icon = "mdi-table"
                  label = "Records"
                  :value = "records"
                ></info-card>
              </v-col>
              <v-col cols = 3>
                <info-card
                  color = "red"
                  icon = "mdi-account"
                  label = "Subjects"
                  :value = "subjects"
                ></info-card>
              </v-col>
              <v-col cols = 3>
                <info-card
                  color = "blue"
                  icon = "mdi-calendar"
                  label = "Visits"
                  :value = "visits"
                ></info-card>
              </v-col>
              <v-col cols = 3>
                <info-card
                  color = "green"
                  icon = "mdi-beaker"
                  label = "Specimen Types"
                  :value = "specimen_types"
                ></info-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols = 6>
                <field-explorer
                  :data="data"
                ></field-explorer>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="show = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>  
</span>
</template>

<script>
  export default {
    name: "statsModal",
    props: {
      data: {
        type: Array,
        required: true
      }
    },
    computed: {
      title() {
        return this.$store.getters['catalog/name'] + " Statistics";
      },
      dictionary() {
        return this.$store.getters['catalog/dictionary'];
      },
      attribute_defs() {
        try {        
          if(this.$store.getters['catalog/ready']){
            let config = this.$store.getters['catalog/config']
            return config.display.attribute_definitions;
          } else {
            return {};
          }
        } catch (e) {
          return {};
        }
      },
      records() {
        return this.data.length.toLocaleString()
      },
      visits() {
        return this.count_uniques(this.attribute_defs.visit_id)
      },
      specimen_types() {
        return this.count_uniques(this.attribute_defs.specimen_type)
      },
      subjects() {
        return this.count_uniques(this.attribute_defs.subject_id)
      },
      show: {
        get() {
          return this.$store.getters.statsModal
        },
        set(value) {
          this.$store.commit('statsModal', value)
        }
      }
    },
    methods: {
      count_uniques(field_name) {
        if(Array.isArray(this.data)) {
          let values = this.data.map(d => d[field_name])
          let count = 0
          values.forEach((item, index) => {
            if(values.indexOf(item) === index) {
              count++
            }
          })
          return count.toLocaleString()           
        } else {
          return "N/A"
        }        
      }
    }
  }
</script>

<style>

</style>