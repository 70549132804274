import jwt from 'jwt-decode'
import Vue from 'vue'

let state = {
  token: null,
  logged_in: false,
  payload: {},
  catalogs: [],
  timeout: null
}

let mutations = {}
let getters = {}

Object.keys(state).forEach(key => {
    
  mutations[key] = (state, payload) => {
    state[key] = payload
  }
  getters[key] = state => {
    return state[key]
  }

});

mutations.token = (state, payload) =>{
  state.token = payload
  localStorage.setItem('token', payload) 
}

getters.payload = (state) =>{
  if(state.token) {
    return jwt(state.token)
  } else {
    return {}
  }
}

getters.catalogs = (state) => {
  return state.catalogs || []
}

getters.full_name = state => {
  return `${state.payload.first_name} ${state.payload.last_name}` 
}

let actions = {
  checkToken: ({dispatch}) => {
    let token = localStorage.getItem('token')
    if(token) {
      let payload = jwt(token)
      if(payload.exp > Date.now() / 1000) {
        dispatch('login', {token})
      }
    }
  },
  async refreshToken({state, commit, dispatch}) {
    let new_token = await Vue.$fetch.put("authenticate", {token: state.token, expiration: '1h'}, "text", true)
    commit('token', new_token)
    commit('payload', jwt(new_token))
    window.setTimeout(() => {
      dispatch('refreshToken')
    }, ((state.payload.exp - Date.now() / 1000) * 1000) - (1000 * 60 * 5)) // 5 minutes before expiration)
    console.log("Token refreshed", state.payload.exp, state.payload.iat)
  },
  async login({state, commit, dispatch}, payload) {
    try {
      let token
      if(payload.token) {
        token = payload.token
      } else {
        token = await Vue.$fetch.post("authenticate", payload, "text", false)
      }
      commit("token", token);
      commit("payload", jwt(token));   
      window.setTimeout(async () => {
        dispatch('refreshToken')
      }, (state.payload.exp - Date.now() / 1000) * 1000) - (1000 * 60 * 5) // 5 minutes before expiration
      let cats = await Vue.$fetch.get("catalogs")
      commit("catalogs", await cats)      
      dispatch("message/success", `You are logged in as ${state.payload.first_name} ${state.payload.last_name}`, {root: true})   
      commit("logged_in", true)         
      return
    } catch(err) {
      console.log(err);
      commit("token", null);
      commit("payload", {});
      commit("logged_in", false)
      dispatch("message/error", err, {root: true});
    }

  },
  logout({commit, dispatch}) {
    commit("token", null);
    commit("payload", {});
    commit("logged_in", false)
    commit("catalogs", [])
    localStorage.removeItem('token');
    dispatch('catalog/unload', null, {root: true})
    dispatch("message/success", "You have been logged out", {root: true})
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
