<template>
  <v-row justify="center">
    <v-dialog
      v-model = "show"
      width = "500px"
      scrollable
    >
      <v-card>
        <iugb-card-title
          title="Join Data"
          icon ="mdi-set-left-center"
          color = "primary"
          showClose
          @close = "show = false"
          :progress="progress"
          :divider_color="color"
        ></iugb-card-title>
        <v-card-text class="mt-4">
          <p>
            Use this dialog to join external data to the catalog. Begin by uploading one 
            or more data files using the <b>File Input</b> field and then click the <b>Join Data</b> button.
          </p>
          <p>
            The catalog will perform a left outer join on the data files and the catalog data based on any/all fields they have in common.
          </p>
          <v-file-input
            v-model = "files"
            label = "Upload Files"
            prepend-icon = "mdi-upload"
            hint = "Upload one or more files"
            accept = "text/csv, application/vnd.ms-excel, text/plain"
            multiple
          ></v-file-input>
          <v-list dense v-if="all_files.length > 0">
            <v-list-item dense v-for="(file, index) in all_files" :key="index">
              <v-list-item-avatar>
                <v-progress-circular
                  :size="20"
                  :width="5"
                  :value="file.progress"
                  color="primary"
                  :indeterminate="file.progress === null"
                  ></v-progress-circular>
                <!-- <v-icon >mdi-file-document</v-icon> -->
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{file.name}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <div>
                <v-btn icon>
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn icon @click="removeFile(file)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="show = false">Close</v-btn>
          <v-btn class="primary" @click="joinData">Join Data</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>  
</template>

<script>

  import Papa from 'papaparse'

  export default {
    name: "joinModal",
    props: {

    },
    data() {
      return {
        files: [],
        all_files: [],
        progress: 100,
        status: null,
        color: 'primary',
      }
    },
    computed: {
      show: {
        get() {
          return this.$store.getters.joinModal
        },
        set(value) {
          this.$store.commit('joinModal', value)
        }
      }
    },
    methods: {
      removeFile(file) {
        this.all_files = this.all_files.filter(f => f !== file)
      },
      setStatus(progress, color) {
        this.progress = progress
        this.color = color
      },
      processFile(file) {
          let f = Papa.parse(file, {header: true,})
          file.data = f
          return file
      },
      loadFile(file) {          
        
        const fr = new FileReader()

        fr.addEventListener('loadstart', () => {
          if(!['success', 'error'].includes(this.status)) {
            this.status = "started"
            this.setStatus(0, 'primary')
          }
        });
        fr.addEventListener('load', () => {
          this.status = "success"
          this.setStatus(100, 'success')
          this.processFile(file, fr.result)
        });
        fr.addEventListener('progress', (e) => {
          if(!['success', 'error'].includes(this.status)) {
            const lp = 100 * e.loaded / e.total;
            this.progress = lp             
          }
        })
        fr.addEventListener('error', () => {
          this.status = "error"
          this.setStatus(100, 'error')
        });
        fr.addEventListener('abort', () => {
          this.status = "error"
          this.setStatus(100, 'warning')
        });

        fr.readAsText(file)

      },
      joinData() {

        this.all_files.forEach(file => {
          console.log("joining file", file.name)
        })

      }
    },
    watch: {
      files(v) {
        if(v.length > 0) {
          v = v.map(file => {
            file = this.loadFile(file)
            return file
          })
          this.all_files = this.all_files.concat(v)
          this.files = []
        }
      }
    }
  }
</script>

<style>

</style>