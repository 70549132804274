<template>
<span >
  <v-card-title :class="`py-0 text-h5 ${color}--text text--darken-3`">
    <v-list class = "py-0" width="100%"> 
      <v-list-item class = "pa-0">
        <v-list-item-icon class="mr-2"><v-icon large left :color="color">{{icon}}</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="text-h5">{{title}}</v-list-item-title>
          <v-list-item-subtitle v-if="subtitle">{{subtitle}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="showClose">
          <v-btn icon>
            <v-icon @click="$emit('close')">mdi-close</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card-title>
  <v-progress-linear :value = "progress" class="mb-0" :color="divider_color"></v-progress-linear>
</span>
</template>

<script>
export default {
    name: 'iugbCardTitle',
    props: {
        title: {
            type: String,
            default: 'Card Title',
        },
        subtitle: {
            type: String,
        },
        icon: {
            type: String,
            default: 'mdi-book',
        },
        color: {
            type: String,
            default: 'primary',
        },
        showClose: {
            type: Boolean,
            default: false,
        },
        progress: {
          type: Number,
          default: 100,
        },
        divider_color: {
          type: String,
          default: 'primary',
        },
    }
}
</script>