<template>
  <div ref = "table"></div>
</template>

<script>

  import {TabulatorFull as Tabulator} from 'tabulator-tables'

  export default {
    name: 'iugbTabulator',
    props: {
      options: {
        type: Object,
        required: true,
      },
      data: {
        required: true,
      },
      column_defs: {
        type: Array,
        required: true
      },
      tabulator: {
        type: Object
      },
      tableHeight: {
        type: Number,
        default: window.innerHeight
      },
      render: {
        type: Boolean,
        default: false
      },
      filters: {
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
        ready: false,
        table: {},
        updater: null,
        columns_updated: false,
        data_updated: false,
        options_updated: false,
        selected_rows: [],
      }
    },
    computed: {
      specimen_id() {
        return this.$store.getters['catalog/specimen_id']
      },
      config() {
        let opts = this.options
        opts.height = this.tableHeight + "px"
        opts.rowFormatter = (row) => {
          let data = row.getData()
          if(this.$store.getters['catalog/cart_ids'].indexOf(data[this.specimen_id]) > -1) {
            row.getElement().classList.add('in-cart')
            // row.getElement().style.fontWeight = 'bold'
            // row.getElement().style.color = '#490'
            // row.getElement().style['background-color'] = "#CCC"
            // row.getElement().style.border = '1px solid #490'
          } else {
            row.getElement().classList.remove('in-cart')
            // row.getElement().style.fontWeight = 'normal'
            // row.getElement().style.color = '#000'
            // row.getElement().style.border = '1px solid #dddddd'
          }
        },
        opts.rowContextMenu = [
          {
            label: "Add to Cart",
            action: (e, row) => {
              if(row.isSelected()) {
                this.$store.dispatch("catalog/addSelectedToCart")
              } else {
                this.$store.commit("catalog/addToCart", row)
              }
            }
          },
          {
            label: "Remove from Cart",
            action: (e, row) => {
              if(row.isSelected()) {
                this.$store.dispatch("catalog/removeSelectedFromCart")
              } else {
                this.$store.commit("catalog/removeFromCart", row)
              }
            }
          }
        ]
        opts.ajaxResponse = this.getExtraData
        return opts
      },
    },
    methods: {
      getExtraData(url, params, response) {

        this.$store.commit("catalog/filteredCount", response.total)
        return response

      },
      destroyTabulator() {
        this.ready = false
        this.$emit("update:tabulator", null)
      },
      async buildTabulator() {
        if(this.render) {
          // this.$emit("update:tabulator", {})
          this.table = new Tabulator(this.$refs.table, this.config)
          this.table.on('tableBuilt', () => { 
            this.ready = true
            this.table.setData(this.data)
            this.table.setColumns(this.column_defs)
            this.$emit("update:tabulator", this.table)  
          })
        }
      },
      async updateOptions() {
        let v = this.config
        if(this.table.initialized) {
          // this.buildTabulator()
          Object.keys(v).forEach(key => {
            if(this.table.getOption(key) !== v[key]) {
              this.table.setOption(key, v[key])
            }
          })
        }
      },
      async updateTabulator() {
        clearTimeout(this.updater)

        this.updater = setTimeout(() => {
          if(!this.table.initialized) {
            this.updateTabulator()
          } else {
            if(this.data_updated) {          
              this.buildTabulator()
            } else if(this.columns_updated) {
              this.table.setColumns(this.column_defs)
            } else if(this.options_updated) {
              this.updateOptions()
            }
            this.columns_updated = false
            this.data_updated = false
            this.options_updated = false
          }
        }, 250)
      }
    },
    async mounted() {
 
    },
    watch: {
      filters: {
        handler(v) {
          if(this.tabulator.initialized) {
            if(v === null) {
              this.tabulator.clearFilter()
            } else {
              this.tabulator.setFilter(() => {}, v)
            }
          }
        },
        deep: true
      },
      options: {
        handler() {
          this.options_updated = true
          this.updateTabulator()
        },
        deep: true,
      },
      tableHeight(v) {
        if(this.table.initialized) {
          this.table.setHeight(v+"px")
        }
      },
      column_defs: {
        handler() {
          this.columns_updated = true
          this.updateTabulator()
        },
      }, 
      data: {
        handler() {
          this.data_updated = true
          this.updateTabulator()
        },
      },
      render(v) {
        if(v) {
          this.$nextTick(() => {
            this.buildTabulator()
          })
        } else {
          this.destroyTabulator()
        }
      }
    }
  }
</script>

<style lang="scss">

  @import '../../styles/simple.scss';

  .tabulator-selected {
    background-color: #3a85cf !important;
    color: #fff !important;
  }

  .in-cart {
    background-color: #159455 !important;
    color: #fff !important;
  }

  .tabulator-selected.in-cart {
    background: repeating-linear-gradient(
      45deg,
      #3a85cf 0px,
      #3a85cf 20px,
      #159455 20px,
      #159455 25px
    );
  }

</style>