import Vue from 'vue'

import cartModal from "./CartModal.vue"
import specimenCartTab from "./SpecimenCartTab.vue"
import cartTab from "./CartTab.vue";

const components = [
    cartModal,
    cartTab,
    specimenCartTab
]


components.forEach(component => {
  Vue.component(component.name, component)
})