<template>
  <v-tab-item>
      <v-card flat>
      <v-card-text>
          <v-simple-table dense>
          <thead>
              <tr>
              <th></th>
              <th v-for="(f, i) in fields" :key="i">{{f}}</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="(row, i) in cart" :key="i">
              <td>
                  <v-btn small icon @click="removeRecord(row)">
                  <v-icon small>mdi-minus</v-icon>
                  </v-btn>
              </td>
              <td v-for="(f, i) in fields" :key="i">{{row[f]}}</td>
              </tr>
          </tbody>
          </v-simple-table>
      </v-card-text>
      </v-card>
  </v-tab-item>    
</template>

<script>
  export default {
    name: 'SpecimenCartTab',
    props: {
      cart: {
        type: Array,
        required: true,
      },
      dictionary: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {}
    },
    computed: {
      specimen_id() {
        return this.$store.getters['catalog/specimen_id']
      },
      fields() {
        let ids = ['specimen_id', 'subject_id', 'visit_id', 'specimen_type_id', 'specimen_quantity_id', 'quantity_units_id']
        let fields = []
        ids.forEach(id => {
          let f = this.$store.getters[`catalog/${id}`]
          if(Array.isArray(f)) {
            f.forEach(i => fields.push(i))
          } else {
            fields.push(f)
          }
        })
        return fields
      },
    },
    methods: {
      removeRecord(record) {
        this.$store.commit('catalog/removeFromCart', record)
      },
    }
  }
</script>