<template>
  <v-card :color = "color">
    <v-card-text>
      <v-row align="center">
        <v-col cols = 4>
          <v-icon dark style="width: 100%; height: 100%;" size="100px">{{icon}}</v-icon>
        </v-col>
        <v-col cols=8>
          <v-row>
            <v-col class= "text-h2 font-weight-regular py-0 white--text" style="text-align: right;" >
              {{value}}
            </v-col>
          </v-row>
          <v-row>
            <v-col class = "text-h5 font-weight-regular py-0 white--text" style="text-align: right;">
              {{label}}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "infoCard",
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: 'mdi-account'
    },
    label: {
      type: String,
      default: 'Label'
    },
    value: {
      type: String,
      default: 'Value'
    },
  },
  data() {
    return {
      show: false
    }
  },
}
</script>