import Vue from 'vue'


import header from "./Header.vue"
import footer from "./Footer.vue"
import sidebar from "./Sidebar.vue"
import main from "./Main.vue"
import snackbar from "./Snackbar.vue"

// Charts
import fieldexplorer from "./charts/FieldExplorer.vue"

// Helpers
import tabulator from "./helpers/Tabulator.vue"
import controller from "./helpers/FieldController.vue"
import cardTitle from "./helpers/CardTitle.vue"
import infocard from "./helpers/InfoCard.vue"

// Modals
import catalogPicker from "./modals/CatalogPicker.vue"
import dictionary from "./modals/DictionaryModal.vue"
import join from "./modals/JoinModal.vue"
import help from "./modals/HelpModal.vue"
import settings from "./modals/SettingsModal.vue"
import login from "./modals/LoginModal.vue"
import loading from "./modals/LoadingModal.vue"
import privacy from "./modals/PrivacyModal.vue"
import disclaimer from "./modals/DisclaimerModal.vue"
import stats from "./modals/StatsModal.vue"

// Filters
import stringFilter from "./filters/StringFilter.vue"
import numericFilter from "./filters/NumericFilter.vue"
import dateFilter from "./filters/DateFilter.vue"
import enumFilter from "./filters/EnumFilter.vue"

const components = [
  header,
  cardTitle,
  footer,
  sidebar,
  main,
  controller,
  login,
  catalogPicker,
  loading,
  tabulator,
  stringFilter,
  numericFilter,
  dateFilter,
  enumFilter,
  dictionary,
  join,
  help,
  settings,
  snackbar,
  privacy,
  disclaimer,
  stats,
  infocard,
  fieldexplorer,
]

components.forEach(component => {
  Vue.component(component.name, component)
})

import "./cart"