const state = {
  message: null,
  visible: false,
  icon: null,
  color: "primary",
  light: false
}

const mutations = {}
const getters = {}

Object.keys(state).forEach(key => {
    
    mutations[key] = (state, payload) => {
      state[key] = payload
    }
    getters[key] = state => {
      return state[key]
    }

});

const actions = {
  error({ commit }, msg) {
    commit("message", msg)
    commit("color", "error")
    commit("icon", "mdi-error")
    commit("visible", true)
  },
  success({ commit }, msg) {
    commit("message", msg)
    commit("color", "success")
    commit("icon", "mdi-check")
    commit("visible", true)
  },
  warning({ commit }, msg) {
    commit("message", msg)
    commit("color", "warning")
    commit("icon", "mdi-alert")
    commit("visible", true)
  },
  message({ commit }, payload) {
    commit("message", payload.message)
    commit("color", payload.color || "primary")
    commit("icon", payload.icon || null)
    commit("visible", true)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}