<template>
  <span>
    <v-range-slider
        v-model="filter_value"
        :min="min_value"
        :max="max_value"
        :step="step"
        hide-details
    ></v-range-slider>
    <v-row>
      <v-col cols = "6">
        <v-text-field
            type = "number"
            v-model = "filter_value[0]"
            :min="min_value"
            :max="max_value"
            hide-details
            outlined
            dense
        ></v-text-field>
      </v-col>
      <v-col cols = "6">
        <v-text-field
            type = "number"
            v-model = "filter_value[1]"
            :min="min_value"
            :max="field.max"
            hide-details
            outlined
            dense
        ></v-text-field>
      </v-col>
    </v-row>



  </span>
</template>

<script>

  export default {
    name: "numericFilter",
    props: {
      value: {
        required: true
      },
      field: {
        type: Object
      }
    },
    data() {
      return {
        timeout: null,
      }
    },
    computed: {
      step() {
        let max = this.field.values.max
        let min = this.field.values.min
        let maxp = Math.floor(max) == max ? 0 : max.toString().split('.')[1].length
        let minp = Math.floor(min) == min ? 0 : min.toString().split('.')[1].length
        return Math.pow(10, -Math.max(maxp, minp))
      },
      min_display: {
        get() {
          return this.filter_value[0]
        },
        set(v) {
          this.filter_value[0] = v
        }
      },
      max_display: {
        get() {
          return this.filter_value[1]
        },
        set(v) {
          this.filter_value[1] = v
        }
      },
      min_value() {
          return this.field.values.min
      },
      max_value() {
        return this.field.values.max
      },
      filter_value: {
        get() {
          if(this.value === null) {
            return [this.field.values.min, this.field.values.max]
          } else {
            return this.value
          }
        },
        set(v) {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            if(v[0] == this.field.values.min && v[1] == this.field.values.max) {
              this.$emit('input', null)
            } else {
              this.$emit('input', v)
            }            
          }, 250)

        }
      }
    },
    mounted() {
      try {
        this.filter_value = [this.field.values.min, this.field.values.max]
      } catch(e) {
        this.filter_value = [0, 0]
      }
    }
  }

</script>