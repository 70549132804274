<template>
  <v-navigation-drawer
    v-model="visible"
    clipped
    app
    v-if="ready"
    width="20%"
    style="min-width: 300px"
  >
    <v-text-field
      class="ma-1 mt-2"
      dense
      label="Search Filters"
      outlined
      v-model="filterSearch"
      prepend-inner-icon="mdi-magnify"
      hide-details
      clearable
    ></v-text-field>
    <v-expansion-panels
      accordion
      multiple
      v-model="opened"
      v-if='ready'
    >
      <field-controller
        v-for="field in fields"
        :key="field.id"
        :value="field"
        v-show="filterFilter(field)"
      ></field-controller>
    </v-expansion-panels>
  </v-navigation-drawer>

</template>

<script>

  export default {
    name: "iugbSidebar",
    props:{
      drawer: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        filterSearch: null,
        opened: []
      };
    },
    computed: {
      catalog_name() {
        return this.$store.getters['catalog/name']
      },
      visible: {
        get() {
          return this.$store.getters.drawer
        },
        set(v) {
          this.$store.commit('drawer', v)
        }
      },
      ready() {
        return this.$store.getters.ready;
      },
      fields() {
        return this.$store.getters['catalog/fields']
      },
    },
    methods: {
      filterFilter(field) {
        if(this.filterSearch) {
          let re = new RegExp(this.filterSearch, 'i');
          return re.test(field.catalog_id) || re.test(field.id);          
        } else {
          return true
        }
      },
    },
    watch: {
      catalog_name() {
        this.opened = []
      }
    }
  }

</script>