<template>
  <span>Date</span>
</template>

<script>

  export default {
    name: "dateFilter",
    props: {
      value: {
        required: true
      },
    },
    data() {
      return {

      }
    }
  }

</script>