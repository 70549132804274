<template>
  <v-row justify="center">
    <v-dialog
      v-model = "show"
      width = "1200px"
    >
      <v-card>
        <iugb-card-title
          title="Data Dictionary"
          icon ="mdi-book"
          color = "primary"
          showClose
          @close = "show = false"
        ></iugb-card-title>
        <v-card-text class="mt-4 pa-0">
          <v-simple-table
            fixed-header
            :height="height"
          >
            <thead>
              <tr>
                <th></th>
                <th>Field</th>
                <th>Type</th>
                <th>Description</th>
                <!-- <th>Values</th> -->
                <!-- <th style = "width: 100px;">Distribution</th> -->

              </tr>
            </thead>
            <tbody>
              <tr v-for="(field,index) in dictionary" :key="index">
                <td>
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-width="100"
                    :open-on-hover="true"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn 
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="primary">mdi-information</v-icon>
                      </v-btn>
                    </template>
                    <v-card width="600">
                      <v-card-title class="py-0">
                        <v-list class = "py-0" width="100%"> 
                          <v-list-item class = "pa-0">
                            <v-list-item-icon class="mr-2"><v-icon>mdi-information</v-icon></v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="text-h5">{{field.catalog_id}}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>

                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-row>
                          <v-col cols=12><span class="font-weight-bold">Description:</span> {{field.description}}</v-col>
                        </v-row>
                        <v-row v-if="field.plot">
                          <v-col cols=5>
                            <v-simple-table dense fixed-header height="150">
                              <thead>
                                <tr>
                                  <th>Value</th>
                                  <th>Count</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(count, key, index) in field.plot" :key="index">
                                  <td>{{key}}</td>
                                  <td>{{count}}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </v-col>
                          <v-col cols=7>
                            <v-sparkline 
                              v-if="field.plot !== 'no'" 
                              :gradient="['#5EF7B7','#0CBB72']"
                              type="bar" 
                              :value='getChartValues(field)' 
                              :labels="getChartLabels(field)"
                              label-size="15"
                              :line-width="300 / Object.keys(field.plot).length "
                              width="300" 
                              height="100"
                            ></v-sparkline>
                          </v-col>
                        </v-row>
                        <v-row v-else>
                          <v-col cols=12 style = "text-align: center; font-weight: bold;">No data available</v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </td>
                <td>
                  {{field.catalog_id}}
                </td>
                <td>{{field.type}}</td>
                <td>{{field.description}}</td>
                <!-- <td style = "font-size: 0.8em" v-html="getValues(field)"></td> -->
                <!-- <td>
                  <v-sparkline 
                    v-if="field.plot !== 'no'" 
                    :gradient="['#5EF7B7','#0CBB72']"
                    type="bar" 
                    :value='getChartValues(field)' 
                    width="30" 
                    height="17"
                  ></v-sparkline>
                </td> -->

              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="show = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>  
</template>

<script>
  export default {
    name: "dictionaryModal",
    computed: {
      height() {
        return window.innerHeight * 0.60
      },
      dictionary() {
        let dictionary = this.$store.getters['catalog/dictionary']
        return dictionary
      },
      show: {
        get() {
          return this.$store.getters.dictionaryModal
        },
        set(value) {
          this.$store.commit('dictionaryModal', value)
        }
      }
    },
    methods: {
      getChartValues(field) {
        try {
          return Object.keys(field.plot).map(key => field.plot[key])
        } catch (e) {
          return []
        }
      },
      getChartLabels(field) {
        try {
          return Object.keys(field.plot)
        } catch(e) {
          return []
        }
      },
      getLabels(field) {
        try {
          return Object.keys(field.values)
        } catch(e) {
          return []
        }
      },
      getValues(field) {
        
        if(field) {        
          if (field.type == "numeric") {
            return `${field.values.min} - ${field.values.max}`
          } else if(field.type == "enum"){
            if(!Array.isArray(field.values)) {
              field.values = [field.values]
            }
            field.values = field.values.filter(x => x !== "")
            if(field.values.length > 5) {
              return `${field.values.length} unqiue values`
            } else if(field.values.length > 0) {
              return `<ul class = "ddvalues">${field.values.map(v => "<li>" + v + "</li>").join("")}</ul>`
            } else {
              return "-"
            }
          } else {
            return "-"
          }
        } else {
          return "-"
        }
      }
    }
  }
</script>

<style>

  .ddvalues {
    padding-left: 0px !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }

</style>